<template>
  <q-page class="q-pa-sm">
    <div class="row full-width">
      <q-table
          v-model:pagination="pagination"
          :columns="columns"
          :loading="loading"
          :rows="rows"
          :rows-per-page-options="[3, 10, 50, 100, 1000]"
          class="full-width"
          flat
          row-key="article_id"
          title="Партнёры"
          @request="onRequest"
      >
        <template v-slot:top-left>
          <q-input v-model="filterName" borderless debounce="300" dense
                   placeholder="Поиск партнёра">
            <template v-slot:append>
              <q-icon name="search"/>
            </template>
          </q-input>
        </template>

        <template v-slot:top-right>
          <q-btn :to="$route.fullPath + '/positions'" color="accent" icon="picture_in_picture"
                 label="Расположение"
                 size="sm"
                 style="margin-right: 10px"/>
          <q-btn :to="$route.fullPath + '/addedit'" color="accent" icon="add"
                 label="Добавить"
                 size="sm"
                 style="margin-left: 10px"/>
        </template>

        <template v-slot:body-cell-image="props">
          <q-td :props="props" class="text-left">
            <q-img
                :src="props.row.logo_url"
                fit="contain"
                loading="lazy"
                style="width: 80px; height: 80px;"
            >
              <template v-slot:error>
                <div
                    class="absolute-full flex flex-center bg-negative text-white rounded-borders"
                    style="opacity: 0.5">
                  <q-icon name="warning" style="font-size: 2rem;"/>
                </div>
              </template>
            </q-img>
          </q-td>
        </template>

        <template v-slot:body-cell-title="{row: entity}">
          <q-td class="text-left">
            <div class="ellipsis" style="max-width: 300px;">
              {{ entity.name }}
              <q-tooltip v-if="entity.name">{{ entity.name }}</q-tooltip>
            </div>
            <q-chip class="q-ml-none" color="green" icon="check_circle_outline" size="sm"
                    text-color="white">
              {{ entity.offer_counters.approved }}
              <q-tooltip>Предложений одобрено</q-tooltip>
            </q-chip>

            <q-chip v-if="entity.offer_counters.pending" color="orange" icon="preview" size="sm"
                    text-color="white">
              {{ entity.offer_counters.pending }}
              <q-tooltip>Преложений на модерации</q-tooltip>
            </q-chip>
          </q-td>
        </template>

        <template v-slot:body-cell-action="props">
          <q-td :props="props" class="text-left">

            <q-toggle
                v-model="props.row.visible"
                checked-icon="visibility"
                color="red"
                label=""
                unchecked-icon="visibility_off"
                @update:model-value="visible => setVisibility(props.row.account_no, visible)"
            >
              <q-tooltip>Видимость в разделе "Наши партнёры"</q-tooltip>
            </q-toggle>

            <q-btn
                :to="$route.fullPath + '/addedit/' + props.row.account_no"
                dense
                icon="edit"
                round
                size="sm">
              <q-tooltip>Редактировать</q-tooltip>
            </q-btn>

            <q-btn
                :to="$route.fullPath + '/details/' + props.row.account_no"
                class="q-ml-sm"
                icon="info"
                label="Подробнее"
                rounded
                size="sm"
                @click="() => 1"
            />

          </q-td>
        </template>

      </q-table>
    </div>
  </q-page>
</template>

<script>
import { AdminApi } from '@/api/apis/admin-api.ts';
import { toHumanDateTime } from '@/helpers/filters/toHumanDate';
import { debounce, Notify } from 'quasar';
import {
  defineComponent, onMounted, ref, watch,
} from 'vue';

const columns = [
  {
    name: 'image',
    field: 'image',
    label: '',
    align: 'left',
  },
  {
    name: 'title',
    label: '',
  },
  {
    name: 'created_at',
    field: 'created_at',
    align: 'center',
    label: 'Дата создания',
    sortable: true,
    format: toHumanDateTime,
  },
  {
    name: 'action',
    label: '',
    align: 'left',
  },
];

export default defineComponent({

  components: {},

  setup() {
    const rows = ref([]);

    const loading = ref(false);
    const filterName = ref('');
    const pagination = ref({
      sortBy: 'created_at',
      descending: true,
      page: 1,
      rowsPerPage: 10,
      rowsNumber: 10,
    });

    async function onRequest(props) {
      props = props || {
        pagination: pagination.value,
      };

      const {
        page,
        rowsPerPage,
        sortBy,
        descending,
      } = props.pagination;

      // debugger;

      loading.value = true;

      const response = await new AdminApi().organizationControllerGetAllOrganizations(
        undefined,
        (page - 1) * rowsPerPage,
        rowsPerPage,
        descending ? 'DESC' : 'ASC',
        filterName.value || undefined,
      );
      const { data } = response.data;
      pagination.value.rowsNumber = response.data.count;
      rows.value.splice(0, rows.value.length, ...data);

      pagination.value.page = page;
      pagination.value.rowsPerPage = rowsPerPage;
      pagination.value.sortBy = sortBy;
      pagination.value.descending = descending;

      loading.value = false;
    }

    onMounted(() => {
      // get initial data from server (1st page)
      onRequest();
    });

    const onRequestDebounced = debounce(onRequest, 500);
    watch(() => filterName.value, () => onRequestDebounced());

    return {
      filterName,
      loading,
      pagination,
      columns,
      rows,
      console,
      onRequest,

      async setVisibility(id, visible) {
        try {
          await new AdminApi().partnerControllerSetOrganizationCardVisibility({ visible }, id);
          Notify.create('Сохранено');
        } catch (e) {
          console.error(e);
        }
      },
    };
  },
});
</script>

<style>

</style>
